<template>
  <LazyHydrate never>
    <v-row :class="type !== 'experts' ? 'mt-10' : 'mt-5'" no-gutters>
      <v-col :cols="type !== 'secret' ? 8 : 12">
        <h2 class="ma-0 ms-3 ms-lg-0 me-3">
          {{ title }}
        </h2>
        <h3
          v-if="type !== 'category'"
          class="ma-0 ms-3 me-3 ms-lg-0 mb-3 subtitle-1 grey--text text--darken-2"
        >
          {{ description }}
        </h3>
      </v-col>
      <v-col v-if="type !== 'secret'" cols="4" class="text-end">
        <v-btn
          v-if="type != 'experts'"
          :to="viewAllLink"
          class="me-3 mt-1 white black--text"
          depressed
        >
          {{ $t('globals.viewAll') }}
        </v-btn>
      </v-col>
    </v-row>
  </LazyHydrate>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
export default {
  name: 'ListTitle',
  components: {
    LazyHydrate
  },

  props: {
    categoryId: {
      type: Number,
      required: false,
      default: 0
    },
    categorySlug: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    market: {
      type: String,
      required: false,
      default: ''
    }
  },

  data () {
    return {
      title: this.getTitle(this.type, this.categoryId, this.market),
      description: this.getDescription(this.type),
      viewAllLink: this.getViewAllLink(this.type, this.categorySlug)
    }
  },

  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },

  methods: {
    getTitle (type, categoryId, market) {
      if (type === 'category') {
        return this.$t('home.introCategory')[categoryId]
      } else {
        switch (type) {
          case 'recentlyAnswered':
            return this.$t('home.introRecentlyAnswered')
          case 'experts':
            return this.$t('home.introExperts', { market: '' })
          case 'urgent':
            return this.$t('home.introUrgent')
          case 'secret':
            return this.$t('home.introSecret')
          case 'recentlyAsked':
            return this.$t('home.introRecent')
          case 'blog':
            return this.$t('nav.blog')
        }
      }
    },
    getDescription (type) {
      if (type === 'category') {
        return ''
      } else {
        switch (type) {
          case 'recentlyAnswered':
            return this.$t('home.introRecentlyAnsweredDesc')
          case 'urgent':
            return this.$t('home.introUrgentDesc')
          case 'secret':
            return this.$t('home.introSecretDesc')
          case 'recentlyAsked':
            return this.$t('home.introRecentDesc')
          case 'blog':
            return this.$t('home.introBlogDesc')
        }
      }
    },
    getViewAllLink (type, categorySlug) {
      if (type === 'category') {
        return this.localePath({
          name: 'consultations-filter',
          params: { filter: categorySlug }
        })
      } else {
        switch (type) {
          case 'recentlyAnswered':
            return this.localePath({
              name: 'consultations-filter',
              query: { sort: 'recently-answered' }
            })
          case 'urgent':
            return this.localePath({
              name: 'consultations-filter',
              params: { filter: 'urgent' }
            })
          case 'recentlyAsked':
            return this.localePath({
              name: 'consultations-filter',
              query: { sort: 'recently-asked' }
            })
        }
      }
    }
  }
}
</script>
