var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-slide-item',{staticClass:"d-inline-block single-item me-3",class:{ desktop: !_vm.isMobile },attrs:{"height":"100%"}},[_c('nuxt-link',{attrs:{"to":_vm.link,"title":_vm.title}},[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"pa-3",attrs:{"height":"100%","flat":"","color":_vm.item.approved !== null? !_vm.item.approved?'red lighten-4':'':'yellow lighten-4'}},[_c('div',{class:{ blure: _vm.item.placeholder }},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('AnswerCount',{attrs:{"model":_vm.item,"linking":false}})],1),_vm._v(" "),_c('v-col',{staticClass:"ps-2 ps-lg-3",attrs:{"cols":"7"}},[(_vm.item.type === 'urgent' || _vm.item.type === 'secret')?_c('div',[(_vm.item.type === 'urgent')?_c('div',{staticClass:"tag urgent warning"},[_c('i',{staticClass:"icon-12 v-icon icon-flash-circle"}),_vm._v(" "),_c('span',{staticClass:"text-caption"},[_c('strong',[_vm._v(_vm._s(_vm.$t('consultation.urgent')))])])]):_c('div',{staticClass:"tag grey darken-3"},[_c('i',{staticClass:"white--text icon-12 v-icon icon-sunglasses"}),_vm._v(" "),_c('span',{staticClass:"white--text text-caption"},[_c('strong',[_vm._v(_vm._s(_vm.$t('consultation.private')))])])])]):_c('p',{staticClass:"ma-0 text-caption grey--text text--darken-2 mb-10"}),_vm._v(" "),_c('p',{staticClass:"ma-0 text-caption grey--text text--darken-2 mt-3"},[_vm._v("\n              "+_vm._s(_vm.item.categoryName)+"\n            ")]),_vm._v(" "),(!_vm.item.placeholder)?_c('p',{staticClass:"ma-0 text-caption grey--text text--darken-2"},[_c('i',{staticClass:"grey--text text--darken-2 icon-12 v-icon icon-clock-outline"}),_vm._v("\n              "+_vm._s(_vm.$t('consultation.asked'))+" "+_vm._s(_vm.item.localTime)+"\n            ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"ma-0 text-caption grey--text text--darken-2"},[_c('i',{staticClass:"grey--text text--darken-2 icon-12 v-icon icon-eye-line"}),_vm._v(" "),(
                  _vm.currentLang !== 'ar' ||
                    (_vm.currentLang === 'ar' &&
                      _vm.item.total_visits !== 1 &&
                      _vm.item.total_visits !== 2)
                )?_c('span',[_vm._v(_vm._s(_vm.item.visits)+" ")]):_vm._e(),_vm._v(_vm._s(_vm.$t(_vm.getProperLabel(_vm.item.total_visits, 'Vists')))+"\n            ")])])],1),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-h6 mt-1 q-title"},[_vm._v("\n              "+_vm._s(_vm.title)+"\n            ")]),_vm._v(" "),(!_vm.item.audio_file_id)?_c('p',{staticClass:"mb-0 text-body-2 grey--text text--darken-3",domProps:{"innerHTML":_vm._s(_vm.excerpt)}}):_c('p',{staticClass:"mb-0 text-body-2 grey--text text--darken-3"},[_c('i',{staticClass:"grey--text text--darken-3 icon-small v-icon icon-mic"}),_vm._v("\n              "+_vm._s(_vm.$t('consultation.voice'))+"\n            ")]),_vm._v(" "),(_vm.item.lastAnswer)?_c('LastAnswer',{staticClass:"mt-2",attrs:{"linked":false,"model":_vm.item.lastAnswer}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.item.isUpgradable)?_c('div',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"no-hover",attrs:{"to":_vm.localePath({
              name: 'pay',
              query: {
                consultationid: _vm.item.id,
                serviceid: _vm.item.upgradingServiceId,
              },
            }),"small":"","color":"primary","nuxt":""}},[_c('i',{staticClass:"me-2 mt-1 v-icon icon-small icon-flash-circle"}),_vm._v("\n          "+_vm._s(_vm.$t('consultation.upgradeCta'))+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.item.placeholder)?_c('div',{staticClass:"expert-notice"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('consultation.private')))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.$t('consultation.onlyExperts')))])])]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }