var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.experts.length > 0)?_c('div',{staticClass:"experts"},[_c('v-slide-group',[_c('v-slide-item',{staticClass:"d-inline-block single-avatar me-3",class:{ desktop: !_vm.isMobile }},[(!_vm.$auth.loggedIn)?_c('a',{staticClass:"d-block ms-lg-0",attrs:{"href":"https://mawadda-eg.com/redirect/discussion/register?type=expert","target":"_blank","rel":"noopener noreferrer"}},[_c('v-avatar',{staticClass:"black--text",class:_vm.isMobile ? 'text-body-1' : 'text-h6',attrs:{"color":"white","size":_vm.isMobile ? 130 : 160}},[_vm._v("\n          ＋ "),_c('br'),_vm._v(_vm._s(_vm.$t('globals.joinUs'))+"\n        ")])],1):_c('nuxt-link',{staticClass:"d-block ms-lg-0",attrs:{"to":_vm.localePath({
            name: 'experts-signup',
            query: { step: '2' }
          })}},[_c('v-avatar',{staticClass:"black--text",class:_vm.isMobile ? 'text-body-1' : 'text-h6',attrs:{"color":"white","size":_vm.isMobile ? 130 : 160}},[_vm._v("\n          ＋ "),_c('br'),_vm._v(_vm._s(_vm.$t('globals.joinUs'))+"\n        ")])],1)],1),_vm._v(" "),(_vm.$fetchState.pending)?_c('div',_vm._l((2),function(n){return _c('v-slide-item',{key:n,staticClass:"d-inline-block single-avatar me-3",class:{ desktop: !_vm.isMobile }},[_c('v-avatar',{staticClass:"rounded-circle",attrs:{"color":"grey lighten-2","size":_vm.isMobile ? 130 : 140}})],1)}),1):_vm._e(),_vm._v(" "),_vm._l((_vm.experts),function(expert){return _c('v-slide-item',{key:expert.id,staticClass:"d-inline-block single-avatar me-3",class:{ desktop: !_vm.isMobile }},[_c('nuxt-link',{staticClass:"d-block ms-lg-0",attrs:{"to":_vm.localePath({
            name: 'experts-id-username',
            params: { id: expert.user.id, username: expert.user.username },
          })}},[_c('v-avatar',{staticClass:"white--text text-subtitle-2",attrs:{"color":"grey","size":_vm.isMobile ? 130 : 140}},[_c('img',{attrs:{"src":expert.avatar,"alt":expert.user.username}})]),_vm._v(" "),_c('div',{staticClass:"text-caption text-center"},[_vm._v("\n          "+_vm._s(expert.user.username)+"\n          "),_c('i',{staticClass:"blue--text v-icon icon-30 icon-shield-check"})])],1)],1)})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }