<template>
  <div class="categories" :class="{ 'desktop pa-4': !isMobile }">
    <v-slide-group>
      <v-slide-item
        v-for="(category, i) in $t('categories')"
        :key="category.id"
        class="d-inline-block single-category me-3"
        :class="{ desktop: !isMobile }"
      >
        <nuxt-link
          :to="
            localePath({
              name: 'consultations-filter',
              params: { filter: category.slug },
            })
          "
          class="d-block ms-lg-0"
          :class="{
            'ms-3': i === 0,
            'me-3 me-lg-0': i === $t('categories').length - 1,
          }"
        >
          <v-img
            :aspect-ratio="!isMobile ? 16 / 5 : 16 / 7"
            :src="`/img/categories/${category.slug}.jpg`"
            width="100%"
            height="60"
          >
            <v-row
              align="end"
              class="lightbox ma-0 white--text pb-2 ps-3 fill-height"
            >
              <h3 class="ma-0 ms-3">
                {{ category.name }}
              </h3>
            </v-row>
          </v-img>
        </nuxt-link>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  name: 'Categories',
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  }
}
</script>
<style lang="scss" scoped>
.categories {
  overflow: hidden;
  &.desktop {
    border-radius: $standard-radius;
    background: $white-color;
    padding-bottom: 16px !important;
  }
}
a {
  &:hover {
    text-decoration: none !important;
    opacity: 0.8;
    border-radius: $standard-radius;
  }
}
.single-category {
  display: inline-block;
  overflow: hidden;
  width: 155px;
  height: 60px;
  background-size: cover;
  border-radius: $standard-radius;

  &.desktop {
    width: 20%;
    height: 60px;
    min-width: 153px;
  }
}
.lightbox {
  box-shadow: 0 0 20px inset rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 72px
  );
}
</style>

<style lang="scss">
.categories {
  .v-slide-group__prev,
  .v-slide-group__next {
    top: 0px;
  }
  .v-slide-group__prev {
    right: -10px;
  }
  .v-slide-group__next {
    left: -10px;
  }
}
.v-application--is-ltr {
  .categories {
    .v-slide-group__prev {
      left: -10px;
      right: unset;
    }
    .v-slide-group__next {
      left: unset;
      right: -10px;
    }
  }
}
</style>
