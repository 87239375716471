var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"categories",class:{ 'desktop pa-4': !_vm.isMobile }},[_c('v-slide-group',_vm._l((_vm.$t('categories')),function(category,i){return _c('v-slide-item',{key:category.id,staticClass:"d-inline-block single-category me-3",class:{ desktop: !_vm.isMobile }},[_c('nuxt-link',{staticClass:"d-block ms-lg-0",class:{
          'ms-3': i === 0,
          'me-3 me-lg-0': i === _vm.$t('categories').length - 1,
        },attrs:{"to":_vm.localePath({
            name: 'consultations-filter',
            params: { filter: category.slug },
          })}},[_c('v-img',{attrs:{"aspect-ratio":!_vm.isMobile ? 16 / 5 : 16 / 7,"src":`/img/categories/${category.slug}.jpg`,"width":"100%","height":"60"}},[_c('v-row',{staticClass:"lightbox ma-0 white--text pb-2 ps-3 fill-height",attrs:{"align":"end"}},[_c('h3',{staticClass:"ma-0 ms-3"},[_vm._v("\n              "+_vm._s(category.name)+"\n            ")])])],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }