<template>
  <div v-if="experts.length > 0" class="experts">
    <v-slide-group>
      <v-slide-item
        class="d-inline-block single-avatar me-3"
        :class="{ desktop: !isMobile }"
      >
        <a
          v-if="!$auth.loggedIn"
          href="https://mawadda-eg.com/redirect/discussion/register?type=expert"
          class="d-block ms-lg-0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-avatar
            class="black--text"
            :class="isMobile ? 'text-body-1' : 'text-h6'"
            color="white"
            :size="isMobile ? 130 : 160"
          >
            ＋ <br>{{ $t('globals.joinUs') }}
          </v-avatar>
        </a>
        <nuxt-link
          v-else
          :to="
            localePath({
              name: 'experts-signup',
              query: { step: '2' }
            })"
          class="d-block ms-lg-0"
        >
          <v-avatar
            class="black--text"
            :class="isMobile ? 'text-body-1' : 'text-h6'"
            color="white"
            :size="isMobile ? 130 : 160"
          >
            ＋ <br>{{ $t('globals.joinUs') }}
          </v-avatar>
        </nuxt-link>
      </v-slide-item>
      <div v-if="$fetchState.pending">
        <v-slide-item
          v-for="n in 2"
          :key="n"
          class="d-inline-block single-avatar me-3"
          :class="{ desktop: !isMobile }"
        >
          <v-avatar
            class="rounded-circle"
            color="grey lighten-2"
            :size="isMobile ? 130 : 140"
          />
        </v-slide-item>
      </div>
      <v-slide-item
        v-for="expert in experts"
        :key="expert.id"
        class="d-inline-block single-avatar me-3"
        :class="{ desktop: !isMobile }"
      >
        <nuxt-link
          :to="
            localePath({
              name: 'experts-id-username',
              params: { id: expert.user.id, username: expert.user.username },
            })
          "
          class="d-block ms-lg-0"
        >
          <v-avatar
            class="white--text text-subtitle-2"
            color="grey"
            :size="isMobile ? 130 : 140"
          >
            <img :src="expert.avatar" :alt="expert.user.username">
          </v-avatar>
          <div class="text-caption text-center">
            {{ expert.user.username }}
            <i class="blue--text v-icon icon-30 icon-shield-check" />
          </div>
        </nuxt-link>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  name: 'Experts',
  data () {
    return {
      experts: []
    }
  },
  async fetch () {
    await this.$axios
      .$get(`/markets/${this.currentMarket.country}/accepted-expert-profiles`)
      .then((response) => {
        this.experts = response.data
      })
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  created () {
    this.currentMarket = this.$t('markets').filter(
      i => i.country === this.$i18n.locale.substring(3, 5)
    )[0]
  }
}
</script>
<style lang="scss" scoped>
.categories {
  overflow: hidden;
  &.desktop {
    border-radius: $standard-radius;
    background: $white-color;
    padding-bottom: 16px !important;
  }
}
a {
  &:hover {
    text-decoration: none !important;
    opacity: 0.8;
    border-radius: $standard-radius;
  }
}
.single-avatar {
  display: inline-block;
  overflow: hidden;
  width: 134px;
  height: 160px;
  min-width: 134px;
  background-size: cover;
  border-radius: $standard-radius;

  &.desktop {
    width: 20%;
    height: 180px;
    min-width: 180px;
  }
}
.lightbox {
  box-shadow: 0 0 20px inset rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.4) 0%,
    transparent 72px
  );
}
</style>

<style lang="scss">
.experts {
  .v-slide-group__prev,
  .v-slide-group__next {
    top: 45px;
  }
  .v-slide-group__prev {
    right: -10px;
  }
  .v-slide-group__next {
    left: -10px;
  }
}
.v-application--is-ltr {
  .experts {
    .v-slide-group__prev {
      left: -10px;
      right: unset;
    }
    .v-slide-group__next {
      left: unset;
      right: -10px;
    }
  }
}
</style>
