<template>
  <div>
    <Placeholder v-if="$fetchState.pending" />
    <v-slide-group
      v-show="!$fetchState.pending"
      class="questions-slider ms-3 ms-lg-0 me-lg-3"
      show-arrows="desktop"
    >
      <Item
        v-for="(item, i) in items"
        :key="i"
        height="100%"
        :item="item"
        :index="i"
        :items-length="items.length"
      />
    </v-slide-group>
  </div>
</template>

<script>
import { shortFormatNumber } from '@/utils'
import Item from '@/components/ConsultationsListingComponents/Item.vue'
import Placeholder from '@/components/ConsultationsListingComponents/Placeholder'
export default {
  name: 'List',

  components: {
    Item,
    Placeholder
  },

  props: {
    categoryId: {
      type: Number,
      required: false,
      default: 0
    },
    userId: {
      type: Number,
      required: false,
      default: 0
    },
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      loading: true,
      items: [],
      fakeSecretQuestion: {
        ar: {
          placeholder: true,
          id: 1,
          market_id: 1,
          category_id: 4,
          title: 'سؤال سري',
          body: 'تفاصيل السؤال سرية ﻻيمكن عرضها',
          type: 'secret',
          has_safe_answer: true,
          total_visits: 1,
          answers_count: 1,
          language: 'ar',
          lastAnswer: {
            user: {
              username: 'المستشار',
              role: 'expert'
            }
          }
        },
        fr: {
          placeholder: true,
          id: 1,
          market_id: 1,
          category_id: 4,
          title: 'Question privée',
          body: 'Seuls les experts peuvent consulter les questions privées',
          type: 'secret',
          has_safe_answer: true,
          total_visits: 1,
          answers_count: 1,
          language: 'ar',
          lastAnswer: {
            user: {
              username: 'Mahkamaty',
              role: 'expert'
            }
          }
        },
        en: {
          placeholder: true,
          id: 1,
          market_id: 1,
          category_id: 4,
          title: 'Secret question',
          body: 'Question details will not be displayed',
          type: 'secret',
          has_safe_answer: true,
          total_visits: 1,
          answers_count: 1,
          language: 'ar',
          lastAnswer: {
            user: {
              username: 'Mahkamaty',
              role: 'expert'
            }
          }
        }
      }
    }
  },

  async fetch () {
    switch (this.type) {
      case 'recentlyAnswered':
        await this.$axios
          .$get(
            `/questions?page-size=5&language=${this.currentLanguageCode}&market_id=${this.currentMarket.id}&order-dir=desc&order-col=last_activity_at&has_safe_answer=1`
          )
          .then((response) => {
            this.items = this.processItemFields(response.data)
            this.loading = false
            this.$emit('loaded')
          })
        break
      case 'urgent':
        await this.$axios
          .$get(
            `/questions?page-size=5&language=${this.currentLanguageCode}&market_id=${this.currentMarket.id}&order-dir=desc&order-col=last_activity_at&has_safe_answer=1&type=urgent`
          )
          .then((response) => {
            this.items = this.processItemFields(response.data)
            this.loading = false
            this.$emit('loaded')
          })
        break
      case 'secret':
        this.items = this.processItemFields(
          Array(5).fill(this.fakeSecretQuestion[this.currentLanguageCode])
        )
        this.loading = false
        this.$emit('loaded')
        break
      case 'recentlyAsked':
        await this.$axios
          .$get(
            `/questions?page-size=5&language=${this.currentLanguageCode}&market_id=${this.currentMarket.id}&order-dir=desc&order-col=created_at`
          )
          .then((response) => {
            this.items = this.processItemFields(response.data)
            this.loading = false
            this.$emit('loaded')
          })
        break
      case 'category':
        await this.$axios
          .$get(
            `/questions?page-size=5&language=${this.currentLanguageCode}&market_id=${this.currentMarket.id}&order-dir=desc&order-col=last_activity_at&has_safe_answer=1&category_id=${this.categoryId}`
          )
          .then((response) => {
            this.items = this.processItemFields(response.data)
            this.loading = false
            this.$emit('category-loaded', this.categoryId)
          })
        break
    }
  },

  created () {
    this.$dayjs.locale(this.$store.state.currentLang)
    this.currentMarket = this.$t('markets').filter(
      i => i.country === this.$i18n.locale.substring(3, 5)
    )[0]
    this.currentLanguageCode =
      this.$store.state.currentLang || this.$i18n.locale.substring(0, 2)
  },

  methods: {
    processItemFields (items) {
      if (items.length) {
        items.forEach((item) => {
          item.categoryName = this.$t('categories').filter(
            i => i.id === item.category_id
          )[0]?.name
          item.localTime = this.$dayjs.utc(item.created_at).local().fromNow()
          item.visits = shortFormatNumber(item.total_visits, 1)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        })
        return items.filter(item => !item.trashed_at)
      } else {
        return items
      }
    }
  }
}
</script>
<style lang="scss">
.v-slide-group {
  &__wrapper {
    overflow: hidden;
  }
}
</style>
<style lang="scss" scoped>
.single-item {
  width: 270px;
  max-width: 270px;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
    .q-title {
      color: $mahkamaty-blue-color-alt !important;
    }
  }
  &.desktop {
    width: 370px !important;
    max-width: unset;
  }
}
a {
  &:hover {
    text-decoration: none;
    color: $mahkamaty-blue-color-alt !important;
  }
}
</style>

<style lang="scss" scoped>
a {
  &:hover {
    text-decoration: none;
  }
}
</style>
