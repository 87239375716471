<template>
  <div>
    <h2 class="ma-0 ms-3 me-3">
      {{ getStatsTitle() }}
    </h2>
    <h3
      v-if="description"
      class="ma-0 ms-3 me-3 subtitle-1 grey--text text--darken-2"
    >
      {{ description }}
    </h3>
    <v-card
      flat
      class="ma-0 ms-lg-3 mt-4 stats-container"
    >
      <v-row
        no-gutters
      >
        <v-col
          v-for="(item, i) in stats"
          :key="i"
          cols="3"
          lg="6"
          class="pa-0 pt-3 pb-3 pt-lg-6 pb-lg-6 text-center item"
        >
          <i
            class="mb-4"
            :class="`v-icon icon-30 icon-${item.iconClass}`"
          /><br>
          <strong>
            {{ item.number }}
          </strong><br>
          {{ item.text }}
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { getProperLabel } from '@/utils'
export default {
  name: 'Stats',

  props: {
    type: {
      type: String,
      required: false,
      default: 'global'
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data () {
    return {
      description: this.type === 'global' ? this.$t('stats.description') : false,
      stats: []
    }
  },

  async fetch () {
    const statsResponse = !this.items.length ? await this.$axios.$get('/stats/counts') : ''
    this.stats = this.items.length
      ? this.items
      : [
          {
            number: statsResponse.questions
              .toString(),
            text: this.$nuxt.context.app.i18n.t(
              getProperLabel(statsResponse.questions, 'Consultations')
            ),
            iconClass: 'format-list-bulleted-square'
          },
          {
            number: statsResponse.answers
              .toString(),
            text: this.$nuxt.context.app.i18n.t(getProperLabel(statsResponse.answers, 'Answers')),
            iconClass: 'shield-check'
          },
          {
            number: statsResponse.users
              .toString(),

            text: this.$nuxt.context.app.i18n.t(getProperLabel(statsResponse.users, 'Users')),
            iconClass: 'account'
          },
          {
            number: statsResponse.comments
              .toString(),

            text: this.$nuxt.context.app.i18n.t(getProperLabel(statsResponse.comments, 'Comments')),
            iconClass: 'comment-text'
          }
        ]
    return { stats: this.stats }
  },

  methods: {
    getStatsTitle () {
      switch (this.type) {
        case 'user':
          return this.$t('user.stats')
        case 'expert':
          return this.$t('expert.stats')
        default:
          return this.$t('stats.title')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item {
  line-height: 0.9;
}
.stats-container {
  border-radius: $standard-radius!important;
}
</style>
